import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message = this.props.data.contactmessage;
    const email = this.props.data.email;
    const emailto = "mailto:" + this.props.data.email;
    const social = this.props.data.social[0];

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Contact</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">


              <div id="message-warning"> Error boy</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Het bericht is verzonden
                <br />
              </div>
            </div>
          </Slide>

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>Contactgegevens</h4>
                <p className="address">
                  {name}
                  <br />
                  {street} <br />
                  {zip}  {city}, {state}
                  <br />
                  <a href="tel:+31646139865"><span>{phone}</span></a>
                  <br />
                  <a href={emailto}> <span>{email}</span></a>
                </p>
              </div>
              <Fade bottom duration={1000}>
                <ul className="social">
                  <a href={social.url} className="button btn project-btn">
                    <i className="fa fa-linkedin"></i>Linked-in
                  </a>
                </ul>
              </Fade>

            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
