import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const emailto = "mailto:" + this.props.data.email;    
    //const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt=""
              />
            </div>
            <div className="nine columns main-col">
              <h2>Informatie</h2>

              <p>{bio}</p>
              <ol>
              <li>Mendix consultancy, implementatie van projecten</li>
              <li>Training en coaching</li>
              <li>Applicatie en enterprise architecture</li>
              <li>Dienstverlening aan de afnemerkant om project beter te laten lopen</li>
              <li>Technische dienstverlening voor complexe Mendix vraagstukken</li>
              <li>Troubleshooting bij Mendix Performance en technische problemen</li>
              <li>Mendix tooling</li>
              <li>Ontwikkeling van modules en widgets voor applicaties of API-diensten.</li>
              <li>Second opinion voor afnemers van Mendix</li>
              <li>Mendix SDK en API's</li>
              </ol>
              <div className="row">
                <div className="columns contact-details">
                  <h2>Adresgegevens</h2>
                  <p className="address">
                    <span>{name}</span>
                    <br />
                    <span>
                      {street}
                      <br />
                      {city} {state}, {zip}
                    </span>
                    <br />
                    <a href="tel:+31646139865"><span>{phone}</span></a>
                    <br />
                    <a href={emailto}><span>{email}</span></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
